var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quota-day" }, [
    _c("div", { staticClass: "quota-day__title" }, [
      _c("div", { staticClass: "quota-day__title-day" }, [
        _vm._v(_vm._s(_vm.getDate.day)),
      ]),
      _vm.isMobile
        ? _c("span", { staticClass: "quota-day__title-slash" }, [_vm._v("/")])
        : _vm._e(),
      _c("div", { staticClass: "quota-day__title-weekday" }, [
        _vm._v(_vm._s(_vm.getDate.weekday)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "quota-day__content" },
      _vm._l(_vm.dayData, function (item, index) {
        return _c(_vm.getCurrentComponent(item), {
          key: index,
          tag: "component",
          attrs: { quota: item },
          on: {
            updateBeforeDeleted: function ($event) {
              return _vm.$emit("updateBeforeDel")
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }